import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanFormModule } from '@pan/lib--web-ui-components/core';
import { AccordionComponent } from './accordion';
import { AccordionGroupComponent } from './accordion-group';

@NgModule({
  imports: [ CommonModule, PanFormModule ],
  exports: [ AccordionComponent, AccordionGroupComponent ],
  declarations: [ AccordionComponent, AccordionGroupComponent ],
})
export class PanAccordionModule { }
