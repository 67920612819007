import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'pan-radio',
  template: `<label [for]="name" [ngClass]="['radio-option', 'radio-option--'+layout]">
  <input
    class="radio-option__input"
    type="checkbox"
    #element
    [name]="group"
    [id]="name"
    (change)="change($event)"
    [checked]="isSelected">
  <i class="radio-option__icon"></i>
  <ng-content></ng-content>
</label>
`,
  styles: [`:host .radio-option--custom .radio-option__icon,:host .radio-option--custom .radio-option__input{position:absolute;width:1px;height:1px;margin:-1px;padding:0;border:0;overflow:hidden;clip:rect(0 0 0 0)}:host{display:inline-block;font-family:Montserrat,sans-serif}:host .radio-option{display:inline-block;width:100%;height:100%}:host .radio-option__input{position:absolute;width:1px;height:1px;margin:-1px;overflow:hidden;clip:rect(0,0,0,0)}:host .radio-option--default{display:-webkit-box;display:flex;position:relative;-webkit-box-align:center;align-items:center;min-height:1.2rem;margin-right:42px;line-height:1rem}:host .radio-option--default .radio-option__input:checked+.radio-option__icon{border:1px solid #09f}:host .radio-option--default .radio-option__input:checked+.radio-option__icon:before{position:absolute;top:50%;left:50%;width:8px;height:8px;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);border-radius:50%;background:#09f;content:''}:host .radio-option--default .radio-option__icon{display:inline-block;position:relative;width:16px;height:16px;margin-right:10px;-webkit-transition:.2s;transition:.2s;border:1px solid #a0bbcb;border-radius:50%;background:#fff;cursor:pointer}:host .radio-option--custom{display:block}`]
})
export class RadioComponent {
  public group: string;
  public layout = 'default';
  public name: string;

  @Input()
  set checked(value: boolean) {
    this.isSelected = value;
  }

  @Input()
  value: string;

  @Input()
  set styleType(value: string) {
    this.layout = value;
    this.cd.detectChanges();
  }

  @Output()
  $onChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('element')
  element: ElementRef;

  @HostBinding('class.selected')
  isSelected = false;

  constructor(
    private cd: ChangeDetectorRef
  ) {}

  change($event) {
    this.$onChange.emit($event.target);
  }
}
