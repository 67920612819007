import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'mh-divider',
  template: `
    <div
      [ngClass]="modifyStyle()"
      class="mh-divider"
      aria-hidden="true">
    </div>
  `,
  styles: [`:host{width:100%}.mh-divider{display:block;width:inherit;height:1px;margin:0 auto}.mh-divider--dark{background-color:#cbcbcb}.mh-divider--light{background-color:#f5f5f5}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MhDividerComponent implements OnInit {

  @Input()
  private modifier: string;

  private styles = ['dark', 'light'];

  ngOnInit() {
    this.modifyStyle();
  }

  /**
   * Checks if property has a value.
   * Checks if modifier equals to 'dark' or 'light' string declarations.
   * Returns a BEM CSS modifier with the string passed as a class argument.
   */
  public modifyStyle() {
    if (!this.modifier) {
      throw new Error('Mh-Divider says: A string value for the modifier must be declared.');
    }

    if (!this.styles.includes(this.modifier)) {
      throw new Error(`Mh-Divider says: The values ${this.styles.join(' or ')} are expected as a style modifier.`);
    }

    return `mh-divider--${this.modifier}`;
  }

}
