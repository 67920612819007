import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanFormModule } from '@pan/lib--web-ui-components/core';
import { FileUploadComponent } from './file-upload/file-upload';
import { FilePlaceholderComponent } from './file-placeholder/file-placeholder';

@NgModule({
  imports: [ CommonModule, PanFormModule ],
  exports: [ FilePlaceholderComponent, FileUploadComponent, CommonModule ],
  declarations: [ FilePlaceholderComponent, FileUploadComponent ],
})
export class PanFileUploadModule { }
