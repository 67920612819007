import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MhDividerComponent } from './divider.component';

@NgModule({
  declarations: [
    MhDividerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MhDividerComponent
  ]
})

export class MhDividerModule { }
