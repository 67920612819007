import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanFormModule } from '@pan/lib--web-ui-components/core';
import { InputPasswordStateIconComponent } from './icons/state-icon/state-icon';
import { InputPasswordComponent } from './input-password';

@NgModule({
  imports: [CommonModule, PanFormModule],
  exports: [InputPasswordComponent, InputPasswordStateIconComponent],
  declarations: [InputPasswordComponent, InputPasswordStateIconComponent]
})
export class PanInputPasswordModule { }
