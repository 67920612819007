import { NgModule } from '@angular/core';
import { MhTextFieldComponent } from './text-field';
import { MhInputDirective } from './input/input';
import { PanFormModule } from '@pan/lib--web-ui-components/core';
import { CommonModule } from '@angular/common';
import { MhTextFieldErrorDirective, MhTextFieldHintDirective } from './input/input-label.directive';


@NgModule({
  imports: [ CommonModule, PanFormModule ],
  exports: [
    MhTextFieldComponent,
    MhInputDirective,
    MhTextFieldErrorDirective,
    MhTextFieldHintDirective,
  ],
  declarations: [
    MhTextFieldComponent,
    MhInputDirective,
    MhTextFieldErrorDirective,
    MhTextFieldHintDirective,
  ],
  entryComponents: [
    MhTextFieldComponent
  ]
})
export class MhTextFieldModule {
}
