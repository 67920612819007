import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanFormModule } from '@pan/lib--web-ui-components/core';
import { InputComponent } from './input';

@NgModule({
  imports: [CommonModule, PanFormModule],
  exports: [InputComponent],
  declarations: [InputComponent],
})
export class PanInputModule { }
