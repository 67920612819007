import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

let _uniqueOptgroupIdCounter = 0;

@Component({
  selector: 'mh-option-group',
  template: `<label class="mh-option-group__label" [id]="_labelId">{{ label }}<ng-content></ng-content></label>
<ng-content select="mh-option, ng-container"></ng-content>
`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:use-input-property-decorator
  inputs: ['disabled'],
  styles: [`.mh-option-group{display:block}.mh-option-group__label{display:block;margin-top:-1px;padding:32px 0 16px 16px;background-color:#e0e0e0;font-size:.75rem;font-weight:700;pointer-events:none}`],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'mh-option-group',
    'role': 'group',
    // '[class.mh-option-group-disabled]': 'disabled',
    // '[attr.aria-disabled]': 'disabled',
    '[attr.aria-labelledby]': '_labelId',
  }
})
export class MhOptionGroupComponent {

  @Input() label: string;

  _labelId = `mh-option-group-label-${_uniqueOptgroupIdCounter++}`;
}
