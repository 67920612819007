import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanFormModule } from '@pan/lib--web-ui-components/core';
import { InputRangeComponent } from './input-range';

@NgModule({
  imports: [CommonModule, PanFormModule],
  exports: [InputRangeComponent],
  declarations: [InputRangeComponent],
})
export class PanInputRangeModule { }
