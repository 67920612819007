import { Component, ViewEncapsulation, HostBinding, Directive, Input } from '@angular/core';

@Directive({
    // Manter o padrÃ£o igual o do component
    // tslint:disable-next-line: directive-selector
    selector: '[mh-link-icon]',
})
export class MhLinkIconDirective {
    @HostBinding('class') mainClass = 'mh-link__icon';
}

@Component({
  selector: '[mh-link]',
  template: '<ng-content></ng-content>',
  styles: [`.mh-link{color:#0277bd;padding:0 2px;-webkit-transition:.1s cubic-bezier(.4,0,1,1);transition:.1s cubic-bezier(.4,0,1,1);background-color:transparent;font-size:16px;font-weight:600;line-height:24px;text-decoration:none;display:inline-block;cursor:pointer}.mh-link .mh-link__icon{fill:#0277bd;display:inline-block;vertical-align:middle}.mh-link:hover{color:#01579b}.mh-link:hover .mh-link__icon{fill:#01579b}.mh-link:active{color:#0288d1}.mh-link:active .mh-link__icon{fill:#0288d1}.mh-link--secondary{font-size:14px;font-weight:700;line-height:20px}.mh-link:active,.mh-link:focus,.mh-link:hover{text-decoration:underline}.mh-link:focus{border-radius:2px;outline:0;box-shadow:0 0 0 2px #0277bd}`],
  encapsulation: ViewEncapsulation.None,
})
export class MhLinkComponent {
    @Input() type = 'default';
    @HostBinding('class') mainClass = `mh-link`;
    @HostBinding('class.mh-link--default') get defaultType() { return this.type === 'default'; }
    @HostBinding('class.mh-link--secondary') get typeSecondary() { return this.type === 'secondary'; }
}
