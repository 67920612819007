import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioOptionComponent } from './radio-option';

@NgModule({
  imports: [CommonModule],
  exports: [RadioOptionComponent],
  declarations: [RadioOptionComponent],
})
export class PanRadioOptionModule { }
