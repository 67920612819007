import { Directive, HostBinding } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'mh-text-field-hint',
})
export class MhTextFieldHintDirective {
    @HostBinding('class') mainClass = 'mh-form-field__hint-label';
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'mh-text-field-error',
})
export class MhTextFieldErrorDirective {
    @HostBinding('class') mainClass = 'mh-form-field__error-label';
}
