import { Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ContentChildren,
  QueryList,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  AfterViewInit,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MhRadioComponent } from './mh-radio';
import { RadioPosition } from './mh-radio.model';

@Component({
  selector: 'mh-radio-group',
  template: '<ng-content></ng-content>',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MhRadioGroupComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MhRadioGroupComponent implements OnInit, AfterViewInit {

  public selected: MhRadioComponent;

  @Output() change = new EventEmitter();
  @ContentChildren(MhRadioComponent) _radios: QueryList<MhRadioComponent>;

  private _name = '';
  @Input()
  get name(): string { return this._name; }
  set name(value: string) {
    if (!value) { return; }
    this._name = value;
    this._updateRadioOption('name', this._name);
  }

  private _position: RadioPosition = 'before';
  @Input()
  get position(): RadioPosition { return this._position; }
  set position(value: RadioPosition) {
    if (!value) { return; }
    this._position = value;
    this._updateRadioOption('position', this._position);
  }

  private _value: any;
  @Input()
  get value(): any { return this._value; }
  set value(newValue: any) {
    if (this._value !== newValue) {
      this._value = newValue;
      this._updateSelectedRadioFromValue();
    }
  }

  private _disabled = false;
  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value) {
    if (!value) { return; }
    this._disabled = value;
    this._markRadiosForCheck();
  }

  private _required = false;
  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    if (!value) { return; }
    this._required = value;
    this._markRadiosForCheck();
  }

  private _selected: MhRadioComponent | null = null;


  onTouched: () => any = () => {};
  _controlValueAccessorChangeFn: (value: any) => void = () => {};


  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.writeValue(this.value);
      this._updateRadioOption('radioGroup', this);
      this._updateRadioOption('name', this._name);
      this._updateRadioOption('position', this._position);
      this._updateSelectedRadioFromValue();
      this.cdRef.detectChanges();
    });
  }

  _emitChangeEvent(event: any) {
    this.change.emit(event);
    this.value = event.value;
    this._controlValueAccessorChangeFn(this.value);
  }

  _updateRadioOption(option: string, value): void {
    if (this._radios) {
      this._radios.forEach(radio => {
        radio[option] = value;
        radio._markForCheck();
      });
    }
  }

  _markRadiosForCheck() {
    if (this._radios) {
      this._radios.forEach(radio => radio._markForCheck());
    }
  }

  _updateSelectedRadioFromValue(): void {

    const isAlreadySelected = this._selected !== null && this._selected.value === this._value;

    if (this._radios && !isAlreadySelected) {
      this._selected = null;
      this._radios.forEach(radio => {
        radio.checked = this.value === radio.value;
        if (radio.checked) { this._selected = radio; }
        radio._markForCheck();
      });

      this._emitChangeEvent({
        element: this,
        value: this.value
      });
    }
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any) {
    this.value = value;
    this.cdRef.markForCheck();
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

}
