import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Observer, forkJoin } from 'rxjs';

import { BaseFormComponent } from '@pan/lib--web-ui-components/core';

export interface FileUploadContract {
  filename: string;
  content: string | ArrayBuffer;
}

let id = 0;

@Component({
  selector: 'pan-file-upload',
  template: `<div class="file-upload">
  <div *ngIf="allowUpload"
    [ngClass]="{
      'file-upload__dropper': true,
      'file-upload__dropper--drag-over': isDragOver,
      'file-upload__dropper--dropped': isDropped
    }"
    (dragover)="prevent($event); onDragOver();"
    (dragenter)="prevent($event); onDragOver();"
    (dragleave)="prevent($event); onDragLeave();"
    (dragend)="prevent($event); onDragLeave();"
    (drop)="prevent($event); handleUpload($event); onDragLeave();"
  >
    <input [id]="uniqueId" (change)="handleUpload($event)" type="file" multiple>
    <label [for]="uniqueId">
      <svg xmlns="https://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <path id="a" d="M11.993 7H12c.305 0 .577.13.76.335l5.93 5.873c.413.41.414 1.074.001 1.484a1.062 1.062 0 0 1-1.494.002L13 10.536v9.51c0 .527-.448.954-1 .954s-1-.427-1-.955v-9.53l-4.195 4.177a1.062 1.062 0 0 1-1.495.002 1.044 1.044 0 0 1-.001-1.484l5.929-5.902c.208-.207.482-.31.755-.308zM4 7.041C4 7.571 3.552 8 3 8s-1-.43-1-.959c0-2.234.911-3.69 2.742-4.024L4.929 3h13.5C20.75 3 22 4.498 22 7.041c0 .53-.448.959-1 .959s-1-.43-1-.959c0-1.566-.465-2.123-1.571-2.123H5.035C4.347 5.082 4 5.687 4 7.04z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <use fill="#09F" fill-rule="nonzero" transform="rotate(-180 12 12)" xlink:href="#a"/>
        </g>
      </svg>
      Arraste ou &nbsp;<b>adicione um arquivo</b>
    </label>
  </div>
  <ng-container *ngFor="let file of files; let i = index">
    <pan-file-placeholder
      [filename]="file.filename"
      [base64]="file.content"
      [canDelete]="allowDelete"
      (deleteClick)="deleteFile(i)"
    >
    </pan-file-placeholder>
  </ng-container>
</div>
`,
  styles: [`.file-upload__dropper input[type=file]{position:absolute;width:1px;height:1px;margin:-1px;padding:0;border:0;overflow:hidden;clip:rect(0 0 0 0)}.file-upload{display:-webkit-box;display:flex;flex-wrap:wrap;-webkit-box-align:center;align-items:center;-webkit-box-pack:start;justify-content:flex-start}.file-upload__dropper{display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;height:56px;margin:16px 8px 0;border:1px dashed #212121;border-radius:4px}.file-upload__dropper--drag-over{background-color:rgba(33,33,33,.2)}.file-upload__dropper label{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;width:100%;height:100%;color:#09f;font-size:12px}.file-upload__dropper label svg{margin-right:10px}.file-upload__dropper label b{text-decoration:underline}.file-upload pan-file-placeholder{display:block;width:calc(50% - 16px);margin:16px 8px 0}`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
  }],
})
export class FileUploadComponent extends BaseFormComponent {

  public uniqueId = `file-upload-${id++}`;
  public isDragOver = false;
  public isDropped = false;
  protected _innerValue: FileUploadContract[] = [];

  @Input()
  set files (values: FileUploadContract[]) {
    this._innerValue = values;
  }

  get files () {
    return this._innerValue;
  }

  @Input()
  allowUpload = true;

  @Input()
  allowDelete = true;

  onDragOver () {
    this.isDragOver = true;
  }

  onDragLeave () {
    this.isDragOver = false;
  }

  prevent (event) {
    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * Handles the upload of multiple files by drop or input upload.
   * @param event Event | DragEvent
   */
  handleUpload (event) {
    this.isDropped = true;

    event = event.dataTransfer
      ? event.dataTransfer // DragEvent
      : event.target; // Input file event

    const { files = [] } = event;

    const files$ = forkJoin(
      Array.from(files).map((file: File) => this.readFile(file))
    );

    const filesSubscriber = files$.subscribe((uploadedFiles: FileUploadContract[]) => {
      this.innerValue = this.innerValue.concat(uploadedFiles);
      filesSubscriber.unsubscribe();
    });
  }

  deleteFile (index: number) {
    this.innerValue.splice(index, 1);
  }

  /**
   * Use the FileReader to read the contents of a File | Blob.
   * Return a observable that resolves into a base64 string
   */
  private readFile (file: File): Observable<FileUploadContract> {

    return Observable.create((obs: Observer<FileUploadContract>) => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);

      reader.onload = () => obs.next({
        filename: file.name,
        content: reader.result
      });

      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(file);
    });
  }
}
