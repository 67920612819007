import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BaseFormComponent } from './base-form.component';

@NgModule({
  imports: [ CommonModule, FormsModule ],
  exports: [ CommonModule, FormsModule, BaseFormComponent ],
  declarations: [ BaseFormComponent ],
})
export class PanFormModule { }
