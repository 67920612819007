import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MhLoadingComponent } from './loading.component';
import { MhLoadOptionComponent } from './option/option';

@NgModule({
  declarations: [
    MhLoadingComponent,
    MhLoadOptionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MhLoadingComponent,
    MhLoadOptionComponent
  ],
  providers: [
  ],
})
export class MhLoadModule { }
