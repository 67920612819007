import { Input, forwardRef, Directive, ElementRef, Optional, Self, NgZone, Inject } from '@angular/core';
import { getSupportedInputTypes, Platform } from '@angular/cdk/platform';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { MhFormFieldControl } from '../form-field-control';
import { MH_INPUT_VALUE_ACCESSOR } from './input-value-accessor';

let nextUniqueId = 0;

@Directive({
  selector: 'mhInput, [mhInput], input[mhInput], textarea[mhInput]',
  exportAs: 'mhInput',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'mh-input-element',
    '[attr.id]': 'id',
    '[attr.placeholder]': 'placeholder',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.readonly]': 'readonly && !_isNativeSelect || null',
    '[attr.aria-describedby]': '_ariaDescribedby || null',
    '[attr.aria-required]': 'required.toString()',
    '(blur)': '_focusChanged(false)',
    '(focus)': '_focusChanged(true)'
  },
  providers: [{ provide: MhFormFieldControl, useExisting: forwardRef(() => MhInputDirective), multi: true }]
})

export class MhInputDirective implements MhFormFieldControl<any> {

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  protected _disabled = false;

  @Input()
  get id(): string { return this._id; }
  set id(value: string) { this._id = value || this._uid; }
  protected _id: string;

  @Input() placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  protected _required = false;

  @Input()
  get type(): string { return this._type; }
  set type(value: string) {
    this._type = value || 'text';

    if (!this._isTextarea() && getSupportedInputTypes().has(this._type)) {
      (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }
  protected _type = 'text';

  @Input()
  get value(): string { return this._inputValueAccessor.value; }
  set value(value: string) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  @Input()
  get readonly(): boolean { return this._readonly; }
  set readonly(value: boolean) { this._readonly = coerceBooleanProperty(value); }
  private _readonly = false;

  constructor(
    protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    protected _platform: Platform,
    ngZone: NgZone,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Self() @Inject(MH_INPUT_VALUE_ACCESSOR) inputValueAccessor: any
  ) {
    const element = this._elementRef.nativeElement;
    this._inputValueAccessor = inputValueAccessor || element;

    if (_platform.IOS) {
      ngZone.runOutsideAngular(() => {
        _elementRef.nativeElement.addEventListener('keyup', (event: Event) => {
          const el = event.target as HTMLInputElement;
          if (!el.value && !el.selectionStart && !el.selectionEnd) {
            el.setSelectionRange(1, 1);
            el.setSelectionRange(0, 0);
          }
        });
      });
    }
  }

  protected _uid = `mh-input-${nextUniqueId++}`;
  private _inputValueAccessor: {value: any};

  focused = false;

  _ariaDescribedby: string;

  _isNativeSelect = false;

  controlType = 'mh-input';

  readonly stateChanges: Subject<void> = new Subject<void>();

  focus(): void {
    this._elementRef.nativeElement.focus();
  }

  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    this._ariaDescribedby = ids.join(' ');
  }

  _onInput() {
  }

  protected _isTextarea() {
    return this._elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
  }

  protected _isBadInput() {
    const validity = (this._elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }

  get empty(): boolean {
    return !this._elementRef.nativeElement.value && !this._isBadInput();
  }

  get shouldLabelFloat(): boolean {
    if (this._isNativeSelect) {
      const selectElement = this._elementRef.nativeElement as HTMLSelectElement;
      const firstOption: HTMLOptionElement | undefined = selectElement.options[0];

      return this.focused || selectElement.multiple || !this.empty ||
        !!(selectElement.selectedIndex > -1 && firstOption && firstOption.label);
    } else {
      return this.focused || !this.empty;
    }
  }

  onContainerClick() {
    if (!this.focused) {
      this.focus();
    }
  }
}
