import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewEncapsulation, HostBinding, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

let _uniqueIdCounter = 0;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mh-load-option',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'role': 'option',
    '[class.mh-load-option--active]': 'active === "active"',
    'class': 'mh-load-option',
  },
  styles: [`:host{position:absolute;top:0;left:50%;width:100%;-webkit-transform:translateX(-50%);transform:translateX(-50%);text-align:center}.mh-load-option__content{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-align:center;align-items:center;color:#fff;font-size:1.125rem;font-weight:600;line-height:26px;vertical-align:center}@media screen and (min-width:600px){.mh-load-option__content{font-size:1.5rem}}`],
  template: `<span class="mh-load-option__content">
  <ng-content></ng-content>
</span>
`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('EnterLeave', [
      state('disabled', style({ top: '-25px', opacity: '0', display: 'none' })),
      state('active', style({ display: 'inline-block', opacity: '1', top: '0px'})),

      transition('active => disabled', [
        style({ display: 'inline-block', opacity: '1', top: '0px'}),
        animate('.3s ease-in')
      ]),
      transition('disabled => active', [
        style({ display: 'none', opacity: '0', top: '25px'}),
        animate('.3s ease-in')
      ]),
    ])
  ]
})
export class MhLoadOptionComponent {

  @Input()
  time: number;

  @Input()
  id: String = `mh-load-option-id-${_uniqueIdCounter++}`;

  @HostBinding('@EnterLeave')
  active = 'disabled';

  constructor(
    private _element: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  select(): void {
    if (this.active !== 'active') {
      this.active = 'active';
      this._changeDetectorRef.markForCheck();
    }
  }

  deselect(): void {
    if (this.active === 'active') {
      this.active = 'disabled';
      this._changeDetectorRef.markForCheck();
    }
  }

  _getHostElement(): HTMLElement {
    return this._element.nativeElement;
  }
}
