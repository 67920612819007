import { Component, Input } from '@angular/core';

@Component({
  selector: 'pan-steps',
  template: `<div class="stepper">
  <ul *ngIf="isStepper()">
    <li *ngFor="let step of steps, let i=index" [ngClass]="{'active': i < activeStep}">
      <span>{{ step }}</span>
    </li>
  </ul>
  <div *ngIf="isProgress()">
    <span [style.width]="progressPercent()"></span>
  </div>
</div>
`,
  styles: [`:host{width:100%}:host .stepper{position:relative;width:100%}:host .stepper>ul{display:-webkit-box;display:flex;position:relative;margin:20px 0 40px;padding:0;list-style:none;text-align:center}:host .stepper>ul>li{position:relative;-webkit-box-flex:1;flex:1;color:#757575}:host .stepper>ul>li:after{position:absolute;top:-19px;left:50%;width:12px;height:12px;margin-left:-6px;border:1px solid #757575;border-radius:100%;background-color:#fff;content:'';z-index:1}:host .stepper>ul>li:before{position:absolute;top:-14px;right:50%;width:100%;height:1px;background-color:#757575;content:''}:host .stepper>ul>li.active{color:#414141}:host .stepper>ul>li.active:after{border-color:#414141;background-color:#414141}:host .stepper>ul>li.active:before{background:-webkit-gradient(linear,right top,left top,color-stop(100%,#414141),to(#414141));background:linear-gradient(to left,#414141 100%,#414141 100%)}:host .stepper>ul>li:first-child:before{background:0 0}:host .stepper>ul>li span{display:none}@media screen and (min-width:992px){:host .stepper>ul>li span{display:inline-block}}:host .stepper>div{display:block;width:100%;height:4px;clear:both}:host .stepper>div span{display:block;height:4px;-webkit-transition:width .5s ease-out;transition:width .5s ease-out;background-color:#212121}`]
})

export class StepsComponent {
  public activeStep = 0;

  @Input() set active(value: number) {
    this.activeStep = value;
  }

  @Input() steps: any;
  @Input() type: String = 'stepper';

  /**
   * Verify if component is type 'progress'
   */
  isProgress() {
    return this.type === 'progress';
  }

  /**
   * Verify if component is type 'stepper'
   */
  isStepper() {
    return this.type === 'stepper';
  }

  /**
   * Calculate progress
   */
  progressPercent() {
    return (this.activeStep / this.steps) * 100 + '%';
  }
}
