import {
  Component,
  OnInit,
  Input
} from '@angular/core';

@Component({
  selector: 'pan-download-button',
  template: `<a class="download-button"
  [title]="title"
  [href]="href"
  target="_blank"
>
  <img [src]="image">
</a>
`,
  styles: [`.download-button{display:-webkit-box;display:flex;position:relative;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;width:120px;height:48px;-webkit-transition:.3s;transition:.3s;border-radius:4px;background:#000;cursor:pointer}.download-button img{align-self:center;max-width:90%}.download-button:hover{opacity:.8}.download-button:active{-webkit-transform:scale(.97);transform:scale(.97)}`]
})
export class DownloadButtonComponent {

  @Input()
  href: string;

  @Input()
  title: string;

  @Input()
  image: string;
}
