import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuesChartComponent } from './values-chart';

@NgModule({
  imports: [CommonModule],
  exports: [ValuesChartComponent],
  declarations: [ValuesChartComponent],
})
export class PanValuesChartModule { }
