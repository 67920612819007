import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadButtonComponent } from './download-button';

@NgModule({
  imports: [ CommonModule ],
  exports: [ DownloadButtonComponent ],
  declarations: [ DownloadButtonComponent ],
})
export class PanDownloadButtonModule { }
