import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'pan-file-placeholder',
  template: `
<div class="file-placeholder">
  <a [href]="base64" [download]="filename" class="file-placeholder__downloadable">
    <svg class="file-placeholder__doc-icon" xmlns="https://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path id="svg-doc-a" d="M8 16h8v2H8v-2zm0-4h8v2H8v-2zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask id="svg-doc-b" fill="#fff">
          <use xlink:href="#svg-doc-a"/>
        </mask>
        <g fill="#09F" mask="url(#svg-doc-b)">
          <path d="M0 0h24v24H0z"/>
        </g>
      </g>
    </svg>

    <div class="file-placeholder__name">{{ filename }}</div>
  </a>

  <div *ngIf="canDelete"
    (click)="deleteClick.emit(); $event.stopPropagation()"
    class="file-placeholder__delete"
  >
    <svg xmlns="https://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path id="svg-delete-a" d="M16 9v10H8V9h8zm-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask id="svg-delete-b" fill="#fff">
          <use xlink:href="#svg-delete-a"/>
        </mask>
        <g fill="#09F" mask="url(#svg-delete-b)">
          <path d="M0 0h24v24H0z"/>
        </g>
      </g>
    </svg>
  </div>
</div>
`,
  styles: [`:host{display:block;width:390px}.file-placeholder{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:start;justify-content:flex-start;width:100%;height:56px;border:2px solid #9e9e9e;border-radius:4px}.file-placeholder:hover{border-color:#212121}.file-placeholder:hover .file-placeholder__doc-icon{color:#9e9e9e}.file-placeholder__downloadable{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:start;justify-content:flex-start;width:calc(100% - 50px);height:100%;padding:0 16px}.file-placeholder__doc-icon{margin-right:10px;color:#9e9e9e}.file-placeholder__name{width:calc(100% - 34px);color:#212121;font-size:12px;font-weight:600;line-height:1.2;word-wrap:break-word}.file-placeholder__delete{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;width:50px;height:100%;margin-left:auto;border-left:2px solid #9e9e9e;cursor:pointer}.file-placeholder__delete svg{-webkit-transition:.25s ease-in;transition:.25s ease-in}.file-placeholder__delete:hover svg{-webkit-transform:translateY(-3px);transform:translateY(-3px)}`]
})
export class FilePlaceholderComponent {

  public _base64: SafeUrl;

  @Input()
  filename: string;

  @Input()
  set base64 (value: any) {
    this._base64 = this.domSanitizer.bypassSecurityTrustUrl(value);
  }

  get base64 () {
    return this._base64;
  }

  @Input()
  canDelete = false;

  @Output()
  deleteClick: EventEmitter<any> = new EventEmitter();

  constructor (
    private domSanitizer: DomSanitizer
  ) {}
}
