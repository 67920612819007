import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanFormModule } from '@pan/lib--web-ui-components/core';
import { RadioComponent } from './radio';
import { RadioGroupComponent } from './radio-group';

@NgModule({
  imports: [CommonModule, PanFormModule],
  exports: [RadioComponent, RadioGroupComponent],
  declarations: [RadioComponent, RadioGroupComponent],
})
export class PanRadioModule { }
