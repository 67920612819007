import { Component, Input } from '@angular/core';

@Component({
  selector: 'pan-input-password-state-icon',
  template: `<!-- state off -->
<svg *ngIf="isPasswordModeOn; else inputStateOn" xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
  <g fill="none" fill-rule="evenodd" stroke="#1984ef" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
      <circle cx="8" cy="8" r="2"/>
      <path d="M1 8c1.795-3 4.128-4.5 7-4.5S13.205 5 15 8c-2.154 3-4.487 4.5-7 4.5S3.154 11 1 8z"/>
  </g>
</svg>

<!-- state on -->
<ng-template #inputStateOn>
  <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd" stroke="#A0BBCB" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
        <circle cx="8" cy="8" r="2"/>
        <path d="M1 8c1.795-3 4.128-4.5 7-4.5S13.205 5 15 8c-2.154 3-4.487 4.5-7 4.5S3.154 11 1 8z"/>
    </g>
  </svg>
</ng-template>
`
})
export class InputPasswordStateIconComponent {

  @Input()
  isPasswordModeOn = true;
}
