import { NgModule } from '@angular/core';
import { MhRadioComponent } from './mh-radio';
import { MhRadioGroupComponent } from './mh-radio-group';

const components = [
  MhRadioComponent,
  MhRadioGroupComponent
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
  imports: []
})
export class MhRadioModule { }
